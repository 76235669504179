<template>
  <div class="card--project card--unit-type" v-if="unitType">
    <!--    <div v-show="unitType.subsidized" class="indicator subsidized">-->
    <!--      {{ $t('general.subsidized') }}-->
    <!--    </div>-->
    <div class="top--part">
      <card-project-image
        :linkDetail="unitType.links ? unitType.links.detail : '/'"
        :photos="photos"
        :primaryPhoto="primaryPhoto"
        :allowMultiImage="allowMultiImage"
        :imageAlt="unitType.name"
      ></card-project-image>
    </div>
    <router-link :to="unitType.links ? unitType.links.detail : '/'" style="text-decoration: none">
      <div class="bottom--part">
        <h3 class="name multiline-ellipsis-2">
          {{ `${$t('general.type')} ${unitType.name}` }}
        </h3>
        <div class="size">
          <span class="mr-4" v-show="unitType.min_building_size"
            >LB {{ compareMinMax(unitType.min_building_size, unitType.max_building_size) }} m2</span
          >
          <span v-show="unitType.min_land_size">LT {{ compareMinMax(unitType.min_land_size, unitType.max_land_size) }} m2</span>
        </div>
        <div class="price--after">
          <span>{{ $t('general.priceStartFrom') }} </span>
          <span>{{ $n(unitType.min_price_num, 'currency', 'id-ID') }}</span>
        </div>
        <!--        <div class="label&#45;&#45;container">-->
        <!--          <div class="d-flex">-->
        <!--            <div class="label&#45;&#45;room ml-2" v-if="unitType.total_floor">-->
        <!--              <v-icon class="mr-2" v-if="unitType.property_type === propertyTypeEnum.HOUSE"-->
        <!--                >mdi-stairs</v-icon-->
        <!--              >-->
        <!--              <v-icon class="mr-2" v-else-if="unitType.property_type === propertyTypeEnum.APARTMENT"-->
        <!--                >mdi-stairs-box</v-icon-->
        <!--              >-->
        <!--              {{ unitType.total_floor }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="fixed--bottom-margin"></div>
        <div class="fixed--bottom">
          <div class="d-flex">
            <div class="label--room" v-show="unitType.min_bedroom !== null">
              <v-icon>mdi-bed</v-icon>
              <div>{{ compareMinMax(unitType.min_bedroom, unitType.max_bedroom) }}</div>
            </div>
            <div class="label--room" v-show="unitType.min_bathroom !== null">
              <v-icon>mdi-shower-head</v-icon>
              <div>{{ compareMinMax(unitType.min_bathroom, unitType.max_bathroom) }}</div>
            </div>
          </div>
          <div class="available">
            <span>{{ $t('general.available') }} </span>
            <span>{{ unitType.total_available_units }} </span>
            <span>{{ $t('general.unit') }} </span>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import CardProjectImage from '@/components/utils/partials/card-project-image';
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  mixins: [HelperMixin],
  components: {
    CardProjectImage,
  },
  props: {
    unitType: {
      default: null,
      require: true,
    },
    premier: {
      default: false,
    },
    showCompare: {
      default: true,
    },
    allowMultiImage: {
      default: false,
    },
  },
  data: () => ({
    debounceCompare: null,
  }),
  computed: {
    ...mapState({
      propertyTypeEnum: (state) => state.v2.masters.propertyTypeEnum,
    }),
    photos() {
      return [
        {
          url: this.unitType.photo,
        },
      ];
    },
    primaryPhoto() {
      return {
        url: this.unitType.photo,
      };
    },
  },
  filters: {
    lowerCase(e) {
      return e.toLowerCase();
    },
  },
};
</script>
